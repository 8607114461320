<template>
  <div class="space-y-6">
    <div
      class="bg-white px-6 py-5 text-left shadow sm:rounded-lg sm:p-6 lg:p-12"
      v-loading="loading"
    >
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <h3 class="text-lg font-medium leading-6 text-gray-900">Settings</h3>
          <p class="mt-1 text-sm text-gray-500">
            Customize how your Organization appears globally across the products
            and services your customers will see. This setup will also affect
            how Invoice looks like.
          </p>
        </div>
        <div class="mt-5 md:col-span-2 md:mt-0">
          <form class="space-y-6" action="#" method="POST">
            <div class="flex w-1/2 justify-between">
              <div>
                <label
                  class="mb-2 flex justify-between text-sm font-medium text-gray-700"
                >
                  <span class="mr-4">Brand color</span>
                  <ShowInfo
                    text="This setup will also affect how Invoice looks like. "
                  />
                </label>
                <el-color-picker
                  v-model="brandColor"
                  color-format="hex"
                  :predefine="predefineColors"
                />
              </div>

              <div>
                <label
                  class="mb-2 flex justify-between text-sm font-medium text-gray-700"
                >
                  <span class="mr-4">Accent color</span>
                  <ShowInfo
                    text="This setup will also affect how Invoice looks like. "
                  />
                </label>
                <el-color-picker
                  v-model="accentColor"
                  color-format="hex"
                  :predefine="predefineColors"
                />
              </div>
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700">
                Photo
              </label>
              <div class="mt-1 flex items-center space-x-5">
                <span
                  class="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100"
                >
                  <img
                    v-if="checkLogo()"
                    class="h-12 w-12 rounded-full"
                    :src="`data:image/png;base64, ${user.currentUser.Organization.LogoImage}`"
                    alt=""
                  />
                  <svg
                    v-else
                    class="h-full w-full text-gray-300"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                    />
                  </svg>
                </span>
              </div>
            </div>

            <div>
              <label class="block text-sm font-medium text-gray-700">
                Change
              </label>
              <el-upload
                class=""
                drag
                accept="image/jpeg,image/gif,image/png"
                action=""
                :on-change="onUploadChange"
                :auto-upload="false"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :before-upload="beforeUpload"
                :limit="1"
                :file-list="fileList"
              >
                <svg
                  class="mx-auto h-12 w-12 text-gray-400"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div class="el-upload__text">
                  <em>Upload a file</em> or drag and drop
                </div>
              </el-upload>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="flex justify-end">
      <button
        type="button"
        class="focus:outline-none rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-indigo-500"
      >
        Cancel
      </button>
      <button
        type="submit"
        @click="onSubmit"
        class="focus:outline-none ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:ring-indigo-500"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import useSettingsRepository from "@/composable/useSettingsRepository";

import ShowInfo from "@/common/components/showInfo";

export default {
  name: "OrganizationSettings",
  components: {
    ShowInfo,
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.state.user);
    const brand = computed(() => {
      if (
        user.value.currentUser !== null &&
        user.value.currentUser.Organization.ColorBrand
      ) {
        return user.value.currentUser.Organization.ColorBrand;
      }

      return "";
    });
    const accent = computed(() => {
      if (
        user.value.currentUser !== null &&
        user.value.currentUser.Organization.ColorAccent
      ) {
        return user.value.currentUser.Organization.ColorAccent;
      }

      return "";
    });
    const { loading, settings, getSettings, saveSettings } =
      useSettingsRepository();

    const brandColor = ref(brand.value);
    const accentColor = ref(accent.value);
    const uploadData = ref({});

    const checkOrg = () => {
      if (
        user.value.currentUser.Organization !== undefined &&
        user.value !== null
      )
        return true;
    };

    const checkLogo = () => {
      if (
        user.value.currentUser !== null &&
        user.value.currentUser.Organization !== undefined &&
        user.value.currentUser.Organization.LogoImage !== undefined &&
        user.value.currentUser.Organization.LogoImage !== null
      )
        return true;
    };

    const predefineColors = ref([
      "#ff4500",
      "#ff8c00",
      "#ffd700",
      "#90ee90",
      "#00ced1",
      "#1e90ff",
      "#c71585",
      "#c7158577",
    ]);

    const onUploadChange = (file) => {
      uploadData.value = file;
    };

    const onSubmit = async () => {
      let branding = {
        primaryColor: brandColor.value,
        secondaryColor: accentColor.value,
      };

      const response = await saveSettings(branding, uploadData.value.raw);

      console.log(response);

      if (response.Item !== undefined) {
        store.dispatch("user/replaceOrgLogo", response.data.Item);
        store.dispatch("user/replaceColorBrand", brandColor.value);
        store.dispatch("user/replaceColorAccent", accentColor.value);
      }

      uploadData.value = {};
    };

    return {
      user,
      loading,
      brandColor,
      accentColor,
      uploadData,
      store,
      settings,
      predefineColors,
      checkOrg,
      checkLogo,
      onSubmit,
      onUploadChange,
      getSettings,
      saveSettings,
    };
  },
};
</script>

<style lang="scss" scoped></style>
