import { ref, readonly } from "vue";

import API from "@/api";
import { ElNotification } from "element-plus";

export default function useSettingsRepository() {
  const settings = ref({});
  const loading = ref(false);

  const getSettings = async () => {
    loading.value = true;
    let response = await API.Organization.Settings.get();

    if (response.data.Success) {
      settings.value = response.data.List;
      loading.value = false;
    } else {
      ElNotification({
        title: "Oops",
        message: "Problem while fetching settings",
        type: "warning",
      });
    }
  };

  const saveSettings = async (branding, data) => {
    loading.value = true;
    let response = await API.Organization.Settings.save(branding, data);

    if (response.data !== undefined) {
      if (response.data.Success) {
        if (response.data.AdditionalMessages !== null) {
          response.data.AdditionalMessages.forEach((message) => {
            ElNotification({
              title: "Success",
              message: message,
              type: "success",
            });
          });
        }

        loading.value = false;

        ElNotification({
          title: "Success",
          message: "New Settings updated",
          type: "success",
        });
      }
    } else {
      loading.value = false;
    }

    return response;
  };

  return {
    loading: readonly(loading),
    settings: readonly(settings),
    getSettings,
    saveSettings,
  };
}
